import React from 'react';
import logo from './logo.svg';
import './App.css';

import {
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
} from '@material-ui/core';
import { Menu } from '@material-ui/icons';

function App() {
  const [state, setState] = React.useState({
    drawer: false,
  });

  const toggleDrawer = (open: boolean) => (
    event: KeyboardEvent | React.MouseEvent<HTMLElement>
  ) => {
    if (
      event instanceof KeyboardEvent &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    )
      return;

    setState({ ...state, drawer: open });
  };

  return (
    <div className="App">
      <AppBar position="static">
        <Toolbar>
          <IconButton edge="start" onClick={toggleDrawer(true)}>
            <Menu />
          </IconButton>
        </Toolbar>
      </AppBar>
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          This is a placeholder. More things to come (plus a higher fidelity
          toaster to boot)
        </p>
      </header>
      <Drawer
        anchor="left"
        open={state.drawer}
        onClose={toggleDrawer(false)}
      ></Drawer>
    </div>
  );
}

export default App;
